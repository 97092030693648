@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);
@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  background-image: url(/static/media/watermark.1e4af2ca.jpg); }

.down-button {
  position: fixed;
  right: 2%;
  bottom: 20%; }

.puzzle-gray {
  background: #efefef; }

.puzzle-text {
  font-style: normal;
  font-family: "Maven Pro", Verdana, "微軟正黑體", sans-serif !important;
  font-size: 1em;
  font-weight: bold; }

.star-mini {
  line-height: 1;
  font-size: 0.6em; }

.p-l-0 {
  padding-left: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.label-center {
  text-align: center;
  line-height: 30px; }

.label-left {
  text-align: left; }

.label-right {
  text-align: right; }

.label-height {
  line-height: 20px; }

.itman-watermark {
  position: absolute;
  display: inline-block;
  z-index: 1;
  width: 20%; }

.itman-watermark img {
  width: 100%;
  height: auto; }

.tag-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 5rem; }
  .tag-content .tag-button {
    color: white;
    padding: 1rem 0.5rem;
    font-size: 1rem;
    border-radius: 0.2rem;
    border: none;
    margin: 5px;
    text-decoration: none;
    width: 30%; }

.follow-color {
  background-color: #00b5ad; }

.unfollow-color {
  background-color: #6c757d; }

.tag-h3 {
  padding: 1rem;
  font-size: 1.5rem;
  font-family: "Maven Pro", Verdana, "微軟正黑體", sans-serif !important; }

.tag-footer {
  position: fixed;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 93%;
  background-color: ghostwhite; }
  .tag-footer .button {
    color: white;
    padding: 0.4rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
    border: none;
    margin: 0.75rem;
    width: 40%;
    text-decoration: none; }

.panel-wrapper {
  position: relative;
  z-index: 99; }
  .panel-wrapper .panel {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateX(25rem);
            transform: translateX(25rem);
    overflow-y: scroll; }
  .panel-wrapper.active .over-layer {
    display: block; }
  .panel-wrapper.active .panel {
    -webkit-transform: translateX(0);
            transform: translateX(0); }

.over-layer {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9; }

.panel {
  width: 25rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.04);
  -webkit-transform: translateX(25rem);
          transform: translateX(25rem);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 99; }
  .panel .head {
    text-align: left;
    width: 100%;
    background-color: #132c33;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 5; }
    .panel .head .close {
      color: #fff;
      cursor: pointer;
      line-height: 1;
      width: 4rem;
      height: 4rem;
      margin-right: 4px;
      border-style: none;
      background-color: #132c33; }
  .panel .body {
    padding: 0.5rem;
    text-align: left; }

.price {
  font-size: 2rem;
  color: #ed5a65; }

.extra .content .input {
  width: 60px; }

.carts-list {
  margin: 1rem; }
  .carts-list .content {
    border-bottom: 2px #5a1216 solid; }
    .carts-list .content .header {
      margin: 0.75rem; }
    .carts-list .content .description {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around; }
      .carts-list .content .description .deprice,
      .carts-list .content .description .sub {
        font-size: 16px;
        color: #1661ab; }
      .carts-list .content .description .button {
        color: white;
        background-color: #ed5a65;
        padding: 0.4rem;
        font-size: 0.75rem;
        border-radius: 0.2rem;
        border: none;
        margin: 0.75rem;
        width: 20%;
        text-decoration: none;
        box-shadow: 0 8px 6px -6px black; }
      .carts-list .content .description .button:hover {
        background-color: #f13c22; }
  .carts-list .sub-content {
    margin: 2rem; }
    .carts-list .sub-content .sub-description {
      display: flex;
      flex-direction: row;
      font-size: 2rem; }
      .carts-list .sub-content .sub-description .deprice {
        font-size: 16px;
        color: #1661ab;
        font-size: 2rem; }

.carts-footer .carts-submit {
  width: 100%;
  background-color: #f28e16;
  color: #fff;
  font-size: 2rem;
  border-style: none;
  border-radius: 5px;
  padding: 1rem; }

.carts-footer .carts-submit:hover {
  background-color: #f9a633; }

.cart-number {
  position: absolute;
  border-radius: 90%;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 20px;
  margin-left: -40px;
  color: #fff;
  background-color: #ed5a65;
  top: -15px;
  z-index: 80;
  padding-top: 8px; }

.preorderForm {
  margin: auto;
  margin-top: 1rem;
  padding: 2rem;
  background: white; }
  .preorderForm .title {
    background-color: #ed5a65;
    font-size: 3rem;
    color: #efefef;
    border: 1rem;
    padding: 2rem;
    text-align: center; }
  .preorderForm .on-submit {
    margin-top: 1rem;
    width: 100%; }

.preorder-list {
  margin: auto;
  margin-top: 1rem;
  padding: 2rem;
  background: white; }

.order-complete {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 900; }

.preorder-item-enter {
  opacity: 0.1;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in; }

preorder-item-enter.preorder-item-enter-active {
  opacity: 0.5; }

.preorder-item-exit {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in; }

.preorder-item-exit.preorder-item-exit-active {
  opacity: 0.1; }

@media screen and (max-width: 300px) {
  .tag-button {
    width: 3rem;
    height: 2.5rem;
    background-color: #9c9c9c;
    color: white;
    border-radius: 10px;
    align-self: center;
    font-size: 0.1rem;
    border: none; } }

