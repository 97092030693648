@charset 'utf-8';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
// @import '../../node_modules/bulma/bulma.sass';

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

// 全局變量
// $family-sans-serif: 'Maven Pro', Verdana, '微軟正黑體', sans-serif!important;

body {
  background-image: url("../img/watermark.jpg");
}

// 定位按鈕
.down-button {
  position: fixed;
  right: 2%;
  bottom: 20%;
  // width: 42px;
  // height: 42px;
  // background-color: #fff;
  // border: 1px solid #ddd;
  // border-radius: 4px;
  // font-size: 12px;
}

// 拼圖灰
.puzzle-gray {
  background: #efefef;
}

// 拼圖文字
.puzzle-text {
  font-style: normal;
  font-family: "Maven Pro", Verdana, "微軟正黑體", sans-serif !important;
  font-size: 1em;
  font-weight: bold;
}

// 間距
.star-mini {
  line-height: 1;
  font-size: 0.6em;
}

// 方塊間距
.p-l-0 {
  padding-left: 0px !important;
}
.p-r-0 {
  padding-right: 0px !important;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

// 文字置中
.label-center {
  text-align: center;
  line-height: 30px;
}

// 文字置左
.label-left {
  text-align: left;
}

// 文字置右
.label-right {
  text-align: right;
}

// 文字間距
.label-height {
  line-height: 20px;
}

// 浮水印
.itman-watermark {
  position: absolute;
  display: inline-block;
  z-index: 1;
  width: 20%;
}
.itman-watermark img {
  width: 100%;
  height: auto;
}

// 標籤主頁面
.tag-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 5rem;

  .tag-button {
    color: white;
    padding: 1rem 0.5rem;
    font-size: 1rem;
    border-radius: 0.2rem;
    border: none;
    margin: 5px;
    text-decoration: none;
    width: 30%;
  }
}

.follow-color {
  background-color: #00b5ad;
}

.unfollow-color {
  background-color: #6c757d;
}

.tag-h3 {
  padding: 1rem;
  font-size: 1.5rem;
  font-family: "Maven Pro", Verdana, "微軟正黑體", sans-serif !important;
}

.tag-footer {
  position: fixed;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 93%;
  background-color: ghostwhite;

  .button {
    color: white;
    padding: 0.4rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
    border: none;
    margin: 0.75rem;
    width: 40%;
    text-decoration: none;
  }
}

// --------- Panel component ------------
.panel-wrapper {
  position: relative; // -- 相對位置 --
  z-index: 99; // -- z軸上層99 --
  .panel {
    transition: all 0.3s ease-in-out; // -- 縮小會回復
    transform: translateX(25rem); // -- 動畫 像X軸移動25rem --
    overflow-y: scroll; // 滾輪
  }
  &.active {
    .over-layer {
      display: block; // -- 區域 --
    }
    .panel {
      transform: translateX(0);
    }
  }
}

.over-layer {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9;
}
.panel {
  width: 25rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.04);
  transform: translateX(25rem);
  transition: all 0.3s ease-in-out;
  z-index: 99;
  .head {
    text-align: left;
    width: 100%;
    background-color: #132c33;
    // --固定head--
    top: 0;
    position: sticky;
    z-index: 5;
    // ------------
    .close {
      // font-size: 24px;
      // font-weight: 600;
      color: #fff;
      cursor: pointer;
      line-height: 1;
      width: 4rem;
      height: 4rem;
      margin-right: 4px;
      border-style: none;
      background-color: #132c33;
    }
  }
  .body {
    padding: 0.5rem;
    text-align: left;
  }
}

// ------------------preorderItem-----------------

.price {
  font-size: 2rem;
  color: #ed5a65;
}

.extra {
  .content {
    .input {
      width: 60px;
    }
  }
}

// -------------------carts----------------
.carts-list {
  margin: 1rem;
  .content {
    border-bottom: 2px #5a1216 solid;
    .header {
      margin: 0.75rem;
    }
    .description {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .deprice,
      .sub {
        font-size: 16px;
        color: #1661ab;
      }
      .button {
        color: white;
        background-color: #ed5a65;
        padding: 0.4rem;
        font-size: 0.75rem;
        border-radius: 0.2rem;
        border: none;
        margin: 0.75rem;
        width: 20%;
        text-decoration: none;
        box-shadow: 0 8px 6px -6px black;
      }
      .button:hover {
        background-color: #f13c22;
      }
    }
  }
  .sub-content {
    margin: 2rem;
    .sub-description {
      display: flex;
      flex-direction: row;
      font-size: 2rem;
      .deprice {
        font-size: 16px;
        color: #1661ab;
        font-size: 2rem;
      }
    }
  }
}

.carts-footer {
  .carts-submit {
    width: 100%;
    background-color: #f28e16;
    color: #fff;
    font-size: 2rem;
    border-style: none;
    border-radius: 5px;
    padding: 1rem;
  }
  .carts-submit:hover {
    background-color: #f9a633;
  }
}

.cart-number {
  position: absolute;
  border-radius: 90%;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 20px;
  margin-left: -40px;
  color: #fff;
  background-color: #ed5a65;
  top: -15px;
  z-index: 80;
  padding-top: 8px;
}

// ------------preorderForm---------------
.preorderForm {
  margin: auto;
  margin-top: 1rem;
  padding: 2rem;
  background: white;
  .title {
    background-color: #ed5a65;
    font-size: 3rem;
    color: #efefef;
    border: 1rem;
    padding: 2rem;
    text-align: center;
  }
  .on-submit {
    margin-top: 1rem;
    width: 100%;
  }
}

// -------------preorder-list---------------
.preorder-list {
  margin: auto;
  margin-top: 1rem;
  padding: 2rem;
  background: white;
}

// ------------- OrderComplete -------------

.order-complete {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.6rem;
  font-weight: 900;
}

// -------------CSSTransition---------------
// 進入前的樣式或是稱作初始值
.preorder-item-enter {
  opacity: 0.1;
  transition: all 0.5s ease-in;
}
// 進入動畫直到完成時之前的 CSS 樣式
preorder-item-enter.preorder-item-enter-active {
  opacity: 0.5;
}
// 退出前的 CSS 樣式
.preorder-item-exit {
  opacity: 1;
  transition: all 0.5s ease-in;
}
// 退出動畫知道完成時之前的的 CSS 樣式
.preorder-item-exit.preorder-item-exit-active {
  opacity: 0.1;
}

// 退出動畫知道完成時的 CSS 樣式
// .preorder-item-enter-done {
//   opacity: 1;
//   background-color: antiquewhite;
// }

@media screen and (max-width: 300px) {
  .tag-button {
    width: 3rem;
    height: 2.5rem;
    background-color: #9c9c9c;
    color: white;
    border-radius: 10px;
    align-self: center;
    font-size: 0.1rem;
    border: none;
  }
}
